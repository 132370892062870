import pickBy from 'lodash/pickBy';

export const imageUtil = {
  getThumbnailURL(path: string, width: number, height?: number): string {
    let tf = new URLSearchParams(
      pickBy({ w: String(width), h: String(height) }, Boolean)
    ).toString();
    tf = process.client
      ? btoa(tf)
      : Buffer.from(tf.toString()).toString('base64');
    const transform = tf.replace(/=+$/, '');
    const newPath = path.replace(/([/\w-]+?)(\.\w+?)?$/, `$1_${transform}$2`);

    return `${useRuntimeConfig().public.api.url}/v1/images/${newPath}`;
  },
};
